import "firebase/auth";

import { isPlatform } from "@ionic/core";
import firebase from "firebase/app";

import { Firebase } from "./firebase";
import { environmentConfig, isTestNodeEnvironment } from "../../../../appV2/environment";
import { FirebaseUpdateError } from "../../../../utils/AppErrors/firebaseCustomErrors";

interface FirebaseAuthOptions {
  appDomain?: string;
  bundleId: string;
  dynamicLinkDomain: string;
}

interface FirebaseResponse {
  error: string | null;
}

const { Auth, RecaptchaVerifier, PhoneAuthProvider } = firebase.auth;

export class FirebaseAuthentication extends Firebase {
  private firebaseAuth: firebase.auth.Auth;
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier | null;
  private verificationId = "";
  private options: FirebaseAuthOptions;

  constructor(firebaseConfig: Record<string, string>, options: FirebaseAuthOptions) {
    super(firebaseConfig);
    this.options = {
      ...options,
      appDomain: options.appDomain || `https://${window.location.hostname}`,
    };
    this.recaptchaVerifier = null;
    this.firebaseAuth = this.FirebaseApp.auth();
    this.firebaseAuth.setPersistence(
      isTestNodeEnvironment() ? Auth.Persistence.NONE : Auth.Persistence.LOCAL
    );
  }

  initRecaptchaVerifier(
    container = "recaptcha-container",
    captchaRef: HTMLElement | null | undefined
  ) {
    if (isPlatform(window, "capacitor")) {
      return;
    }
    if (!captchaRef) {
      return;
    }

    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear();
    }

    captchaRef.innerHTML = `<div id="${container}"></div>`;

    this.recaptchaVerifier = new RecaptchaVerifier(
      container,
      {
        size: "invisible",
      },
      this.FirebaseApp
    );
  }

  signInWithCustomToken(token: string) {
    return this.firebaseAuth.signInWithCustomToken(token);
  }

  async webPhoneSignIn(phoneNumber: string) {
    try {
      const confirmationResult = await this.firebaseAuth.signInWithPhoneNumber(
        phoneNumber,
        this.recaptchaVerifier as firebase.auth.ApplicationVerifier
      );
      this.verificationId = confirmationResult.verificationId;
      return { error: null };
    } catch (error) {
      const err = error as firebase.auth.Error;
      return this.onFirebaseError(err);
    }
  }

  async reAuthWithPhoneNumber(phoneNumber: string) {
    try {
      const confirmationResult = await this.firebaseAuth.currentUser?.reauthenticateWithPhoneNumber(
        phoneNumber,
        this.recaptchaVerifier as firebase.auth.ApplicationVerifier
      );
      this.verificationId = confirmationResult?.verificationId as string;
      return { error: null };
    } catch (error) {
      const err = error as firebase.auth.Error;
      return this.onFirebaseError(err);
    }
  }

  async confirmPhoneCode(verificationCode: string): Promise<FirebaseResponse> {
    const credential = PhoneAuthProvider.credential(this.verificationId, verificationCode);
    return this.firebaseAuth
      .signInWithCredential(credential)
      .then(() => {
        return { error: null };
      })
      .catch(this.onFirebaseError);
  }

  async signInWithEmail(email: string): Promise<FirebaseResponse> {
    const { appDomain, bundleId } = this.options;
    const actionCodeSettings = {
      url: `${appDomain}/welcome/login/linkVerify`,
      handleCodeInApp: true,
      iOS: {
        bundleId,
      },
      android: {
        packageName: bundleId,
        installApp: true,
      },
      dynamicLinkDomain: environmentConfig.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN,
    };

    return this.firebaseAuth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(this.onEmailSent(email))
      .catch(this.onFirebaseError);
  }

  private onEmailSent = (email: string) => (): FirebaseResponse => {
    localStorage.setItem("emailToVerify", email);
    return { error: null };
  };

  private onEmailVerified = (): FirebaseResponse => {
    localStorage.removeItem("emailToVerify");
    return { error: null };
  };

  async signInWithEmailLink(link?: string, email?: string): Promise<FirebaseResponse> {
    const emailToLogin = email || localStorage.getItem("emailToVerify");
    const url = link || window.location.href;

    return this.firebaseAuth
      .signInWithEmailLink(emailToLogin as string, url)
      .then(this.onEmailVerified)
      .catch(this.onFirebaseError);
  }

  private onFirebaseError = (error: firebase.auth.Error): FirebaseResponse => {
    switch (error.code) {
      case "auth/user-disabled":
        return {
          error:
            "Your account has been deactivated, if you believe this is in error please call or text us.",
        };

      case "auth/network-request-failed":
        return {
          error: "Network Connectivity error. Check your network connection and try again later.",
        };

      case "auth/quota-exceeded":
        return {
          error: "Verification code error. Try again after after an hour.",
        };

      case "auth/expired-action-code":
        return {
          error: "Verification code expired. Try requesting again",
        };

      case "auth/invalid-email":
        return {
          error: "Invalid email address",
        };

      case "auth/invalid-verification-code":
        return {
          error: "Invalid verification code",
        };

      case "auth/invalid-action-code":
        return {
          error:
            "Link verification failed. Please try again or contact Support if the issue persists.",
        };

      default:
        return {
          error: `Received an error - ${error.code} | ${error.message}`,
        };
    }
  };

  get currentUser() {
    return this.firebaseAuth.currentUser;
  }

  async updateCurrentUserName(name: string) {
    await this.firebaseAuth.currentUser?.updateProfile({ displayName: name });
  }

  async updateCurrentUserEmail(email: string): Promise<void> {
    try {
      await this.firebaseAuth.currentUser?.updateEmail(email);
    } catch (error: any) {
      throw new FirebaseUpdateError(error.code);
    }
  }

  reloadUser() {
    this.firebaseAuth.currentUser?.reload();
  }

  onAuthStateChanged(callback: (user: firebase.User | null) => Promise<void>) {
    return this.firebaseAuth.onAuthStateChanged(callback);
  }

  onIdTokenChanged(callback: (user: firebase.User | null) => Promise<void>) {
    return this.firebaseAuth.onIdTokenChanged(callback);
  }

  signOut() {
    return this.firebaseAuth.signOut();
  }
}
